<template>
  <div>
    <h2 v-html="$t('search_buyer_organisation_title')"></h2>
    <v-select
      id="search_buyer_organisation_id"
      v-model="searchCardStore.BuyerOrganisation_id"
      :label="$t('search_buyer_organisation_name')"
      :items="buyerOrganisations"
      item-title="name"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchOrganisationStore } from '@/store/SearchOrganisationStore'

import { useOrganisationsStore } from '@/store/enums/OrganisationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchBuyerOrganisation',
  extends: baseSearchVue,
  data() {
    return {}
  },
  computed: {
    buyerOrganisations() {
      return useOrganisationsStore().buyerOrganisations
    }
  },
  beforeMount() {
    useOrganisationsStore().fetchBuyerOrganisations()
    this.setSearchCardStore(useSearchOrganisationStore(), true)
  }
})
</script>
